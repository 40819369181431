import { useTheme } from '../../contexts/ThemeContext/ThemeContext';
import './EmptyText.scss';

interface IProps {
  className?: string;
  index?: number;
  length?: number;
  lengths?: number[];
  height?: number;
  type: TagType;
  animation?: boolean;
}

type TagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span';

export function EmptyText(props: IProps) {
  const { className, index, length, lengths, height, type, animation = false } = props;
  const { theme } = useTheme();
  let widthUnit = 'ch';
  let width = length ?? 0;
  let heightUnit = 'em';
  let textHeight = height ?? 1;

  const TagName = type as keyof JSX.IntrinsicElements | React.ComponentType<any>;

  // Use length from the lengths array if length is not defined,
  // loop around if index is greather than the array length
  if (length === undefined && lengths !== undefined && index !== undefined) {
    width = lengths[index % lengths.length];
  }

  return (
    <TagName className={className}>
      <span
        className="empty-text"
        data-animation={animation}
        data-theme={theme}
        style={{ width: `${width}${widthUnit}`, height: `${textHeight}${heightUnit}` }}
      >
        {'\u00a0'}
      </span>
    </TagName>
  );
}
